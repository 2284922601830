import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


// Import containers
import { LoginComponent } from '../app/views/login/login.component';

// Import Containers
import { DefaultLayoutComponent } from './containers';

// Import Dashboard
import { DashboardComponent } from '../app/views/dashboard/dashboard.component';

// Import routing module
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FieldsetModule } from 'primeng/fieldset';
import { PanelModule } from 'primeng/panel';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'registraties',
        loadChildren: './views/registraties/registraties.module#RegistratiesModule'
      },
      {
        path: 'management',
        loadChildren: './views/management/management.module#ManagementModule'
      },
      {
        path: 'performance',
        loadChildren: './views/performance/performance.module#PerformanceModule'
      },
    ]
  }
];

@NgModule({
  imports: [
    BsDropdownModule,
    TabsModule,
    FieldsetModule,
    PanelModule,
    DialogModule,
    TooltipModule,
    RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
