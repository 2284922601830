import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FoutomschrijvingenService {

  constructor(
    private $http: HttpClient,
  ) { }

  getRecords(): any {
    return this.$http
      .get('/api/foutomschrijvingen/getAll')
      .toPromise();
  }
  getAllWithFoutsoort(): any {
    return this.$http
      .get('/api/foutomschrijvingen/getAllWithFoutsoort')
      .toPromise();
  }

  getAllWithFoutsoortKDS(): any {
    return this.$http
      .get('/api/foutomschrijvingen/getAllWithFoutsoortKDS')
      .toPromise();
  }

  create(data): any {
    return this.$http.post('/api/foutomschrijvingen/create', data)
      .toPromise()
      .catch((err) => console.error(err));
  }

  update(data): any {
    return this.$http.post('/api/foutomschrijvingen/update', data)
      .toPromise()
      .catch((err) => console.error(err));
  }
}
