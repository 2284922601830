export const navItemsCoordinator = [
  {
    label: 'Performance',
    items: [
      [
        {
          items: [
            {
              label: 'Individueel',
              routerLink: ['/performance/individuele-performance']
            },
            {
              label: 'Team',
              routerLink: ['/performance/team-performance']
            }
          ]
        }
      ]
    ]
  },
  {
    label: 'Registraties',
    items: [
      [
        {
          items: [
            {
              label: 'Mijn Registraties',
              routerLink: ['/registraties/mijn-registraties']
            },
            {
              label: 'Alle Registraties',
              routerLink: ['/registraties/alle-registraties']
            }
          ]
        }
      ]
    ]
  },
  {
    label: 'Managment',
    items: [
      [
        {
          items: [
            {
              label: 'Gebruikers',
              routerLink: ['/management/gebruikers-pagina']
            },
            {
              label: 'Configuraties',
              routerLink: ['/management/configuraties']
            }
          ]
        }
      ]
    ]
  }
];
