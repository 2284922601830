import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import ls from 'localstorage-slim';


@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {

  userData = {
    username: '',
    password: ''
  };

  loginError = false;
  loading = true;
  message = ''

  displayRegisterForm = false;

  constructor(
    private $router: Router,
    private $auth: AuthenticationService
  ) { }

  doLogin(loginForm: NgForm) {
    this.$auth.authenticateUser(loginForm.value.username, loginForm.value.password).subscribe(res => {
      if (res !== false) {
        this.$router.navigate(['/performance/individuele-performance']);
        // localStorage.setItem('user', JSON.stringify(res));
        ls.set('user', JSON.stringify(res), { encrypt: true });
      } else {
        this.loginError = true;
      }
    });
  }

  doSamlLogin() {
    if (localStorage.getItem('user') !== null) {
      this.$router.navigate(['/performance/individuele-performance']);
    } else {
      this.$auth.authenticateSamlUser().subscribe(res => {
        if (res.json().sso_link) {
          window.location.replace(res.json().sso_link)
        } else {
          if (res.json() == "inactive") {
            this.loginError = true
            this.message = 'ONGELDIGE INLOGGEGEVENS. VOER DE JUISTE GEGEVENS IN.'
          } else if (res.json() == "not found") {
            this.loginError = true
            this.message = 'JE ACCOUNT IS UITGESCHAKELD. NEEM CONTACT OP MET JE ADMINISTRATOR'
          } else {
            ls.set('user', JSON.stringify(res.json()), { encrypt: true });
            this.loginError = false
            this.message = ''
          }
        }
      });
    }

  }

  ngOnInit() {
    if (JSON.parse(ls.get('user', { decrypt: true })) !== "true") {
      this.$auth.authenticateSamlUser().subscribe(res => {
        if (!res.json().sso_link) {
          if (res.json() == "inactive") {
            this.loginError = true
            this.message = 'JE ACCOUNT IS UITGESCHAKELD. NEEM CONTACT OP MET JE ADMINISTRATOR'
            this.loading = false
          } else if (res.json() == "not found") {
            this.loginError = true
            this.message = 'ONGELDIGE INLOGGEGEVENS. VOER DE JUISTE GEGEVENS IN.'
            this.loading = false
          } else {
            ls.set('user', JSON.stringify(res.json()), { encrypt: true });
            this.$router.navigate(['/performance/individuele-performance'])
          }
          // ls.set('user', JSON.stringify(res.json()), { encrypt: true });
          // console.log(res.json())

        } else {
          this.loading = false
        }
      });
    }
  }

}
