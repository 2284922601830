import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './views/login/login.component';

import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MegaMenuModule } from 'primeng/megamenu';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';

// Import services
import { AuthenticationService } from './services/authentication.service';


// Import containers
import { DefaultLayoutComponent } from './containers';

// Import routing module
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';


@NgModule({
  imports: [
    AppAsideModule,
    AppHeaderModule,
    AppFooterModule,
    AppSidebarModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    ReactiveFormsModule,
    MessagesModule,
    MessageModule,
    DialogModule,
    MegaMenuModule,
    ToastModule,
    TableModule,
    BsDropdownModule.forRoot(),
  ],
  providers: [
    AuthenticationService,
    MessageService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    LoginComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
