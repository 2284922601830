import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { HelperService } from '../../utils/helper.service';
import { Http } from '@angular/http';
import { navItemsOperator } from '../side-menu/_nav_operator';
import { navItemsControleur } from '../side-menu/_nav_controleur';
import { navItemsCoordinator } from '../side-menu/_nav_coordinator';
import { navItemsAdministrator } from '../side-menu/_nav_administrator';
import { FOUTOMSCHRIJVING_POPUP_COLS } from './../../constants/foutomschrijving-popup-cols';
import { FoutomschrijvingenService } from '../../services/foutomschrijvingen.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {

  // Settings from database
  settingvalue = [];
  displayEditProfile = false;
  displayBasic: boolean;
  loading: boolean;

  foutsoortomschrijvingArray: any;
  foutsoortomschrijvingTableCols = FOUTOMSCHRIJVING_POPUP_COLS;

  public navItems;
  public allNavItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  // public username: string = this.$userService.getLoggedUser().username;
  public fullname: string = this.$userService.getLoggedUser().fullname;

  constructor(
    private $router: Router,
    private $userService: UserService,
    private $helper: HelperService,
    private $http: Http,
    private $foutomschrijvingService: FoutomschrijvingenService,
  ) {

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });

    switch (this.$userService.getLoggedUserRoleID()) {
      case 1:
        /* Eindklant */
        this.navItems = navItemsAdministrator;
        break;
      case 2:
        /* Coordinator */
        this.navItems = navItemsCoordinator;
        break;
        case 3:
        /* Controleur */
        this.navItems = navItemsControleur;
        break;
      case 4:
        /* Operator Dual */
        this.navItems = navItemsOperator;
        break;
      default:
        break;
    }
    this.allNavItems = JSON.parse(JSON.stringify(this.navItems));

  }


  doLogout() {
    /* remove the user session */
    localStorage.removeItem('user');
    /* redirect the user to the login */
    this.$router.navigate(['/login']);
  }
  profielBewerken() {
    this.displayEditProfile = true;
  }

  getFoutsoortomschrijving() {
    this.loading = true;
    this.$foutomschrijvingService.getAllWithFoutsoortKDS().then(res => {
      this.foutsoortomschrijvingArray = res;
      this.loading = false;
    });

  }

  showBasicDialog() {
    this.displayBasic = true;
    this.getFoutsoortomschrijving();
  }
}
