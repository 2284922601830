import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../utils/helper.service';
import ls from 'localstorage-slim';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private $http: HttpClient,
    private $helper: HelperService
  ) {  }

  public getLoggedUser() {
    // return JSON.parse(localStorage['user']);
    return JSON.parse(ls.get('user', { decrypt: true }));
  }

  // getLoggedUsername(): string {
  //   // return JSON.parse(localStorage['user']).username;
  //   return JSON.parse(ls.get('user', { decrypt: true })).username;
  // }

  getLoggedUserID(): number {
    // return JSON.parse(localStorage['user']).id;
    return JSON.parse(ls.get('user', { decrypt: true })).id;
  }

  getLoggedUserRoleID(): number {
    // return JSON.parse(localStorage['user']).role_id;
    return JSON.parse(ls.get('user', { decrypt: true })).role_id
  }

  getLoggedUserType(): number {
    // return JSON.parse(localStorage['user']).type_id;
    return JSON.parse(ls.get('user', { decrypt: true })).type_id
  }

  getUsers(): any {
    return this.$http.get('/api/users/getAll')
      .toPromise()
      .catch(() => this.$helper.showConnectionBrokenAlert());
  }

  getRoles(): any {
    return this.$http.get('/api/users/getAllRoles')
      .toPromise()
      .catch(() => this.$helper.showConnectionBrokenAlert());
  }

  getUser(userID: number) {
    return this.$http.post('/api/users/getAll', {
      'user_id': userID
    })
      .toPromise()
      .catch(() => this.$helper.showConnectionBrokenAlert());
  }

  getRecords(): any {
    return this.$http
      .get("/api/users/getAll")
      .toPromise()
  }

  create(data) {
    return this.$http.post('/api/users/create', data)
      .toPromise()
      .catch((err) => console.error(err));
  }

  update(data) {
    return this.$http.post('/api/users/update', data)
      .toPromise()
      .catch((err) => console.error(err));
  }
}
